import "windi.css";

import("./bootstrap");

declare global {
  interface Window {
    gtag: (command: string, eventName: string, options?: object) => void;
  }
}

export {};
